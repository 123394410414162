// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { CompanyAPI } from 'api';
// Interfaces and types
import { IErrorRes, TQueryParams } from 'types/appTypes';
import {
  IGetCompaniesRes,
  IGetLogInAsCompanyRes,
  IDeleteCompaniesRes,
  IDeleteCompaniesParams,
  IGetCompanyDetailsRes,
  IUpdateCompanyRes,
  IUpdateCompanyParamWithBody,
} from 'types/companyTypes';

const getCompaniesAsync = createAsyncThunk<
  IGetCompaniesRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>('@company/getCompanies', async (cred: TQueryParams, { rejectWithValue }) => {
  try {
    const data = await CompanyAPI.getCompanies(cred);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const signInAsCompanyAsync = createAsyncThunk<
  IGetLogInAsCompanyRes,
  number,
  { rejectValue: IErrorRes }
>('@company/signInAsCompany', async (companyId: number, { rejectWithValue }) => {
  try {
    const data = await CompanyAPI.signInAsCompany(companyId);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteCompanyAsync = createAsyncThunk<
  IDeleteCompaniesRes,
  IDeleteCompaniesParams,
  { rejectValue: IErrorRes }
>(
  '@company/deleteCompany',
  async (params: IDeleteCompaniesParams, { rejectWithValue }) => {
    try {
      const data = await CompanyAPI.deleteCompany(params);

      return data;
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getCompanyDetails = createAsyncThunk<
  IGetCompanyDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('@company/getCompanyDetails', async (id: number, { rejectWithValue }) => {
  try {
    const data = await CompanyAPI.getCompanyDetails(id);

    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateCompanyAsync = createAsyncThunk<
  IUpdateCompanyRes,
  IUpdateCompanyParamWithBody,
  { rejectValue: IErrorRes }
>(
  '@company/updateCompanyDetails',
  async ({ id, body }: IUpdateCompanyParamWithBody, { rejectWithValue }) => {
    try {
      const data = await CompanyAPI.updateCompany({ id, body });

      return data;
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getCompaniesAsync,
  signInAsCompanyAsync,
  deleteCompanyAsync,
  getCompanyDetails,
  updateCompanyAsync,
};
